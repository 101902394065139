.isam-logo {
  width: 320px;
  height: 30px;
}

@media screen and (max-width: 575px) {
  .hamburger-menu {
    width: 24px;
    height: 14px;
  }
  .isam-logo-wrapper {
    order: 3;
  }
  .logo {
    .isam-logo {
      // width: 199px;
      height: 18px;
      margin-left: -50px;
    }
    .e-kaynaklar-logo {
      height: 20px;
      margin-top: 6px;
    }
  }
}

@media screen and (max-width: 990px) {
  .isam-logo-mobile {
    width: 60px !important;
  }
  .ant-layout-header {
    height: 65px !important;
  }
  .e-kaynaklar-logo {
    margin-top: 6px;
    height: 20px;
  }
}
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.padding-fixed-header {
  padding-top: 140px;
}
@media screen and (max-width: 575px) {
  .padding-fixed-header {
    padding-top: 80px;
  }
}
.gx-main-content-wrapper {
  padding: 32px 0px 32px 0 !important;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 16px 0 !important;
  }
}
.full-width {
  width: 100vw;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
.shrinked-header {
  height: 45px !important;
  transition: all 0.5s;
}
